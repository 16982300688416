import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import shouldAnimate from '../lib/ReducedMotion';

let isScrolling = false;

let items = [];
let recheckTimeout = -1;

let lastEventDelay = 0;
let lastEventTime = (new Date()).getTime();

const init = () => {
    addElements($('body'));
    
    Viewport.on('scroll', onScroll);
    Viewport.on('resize', onResize);
};

const addElements = ($el) => {
    const $animatorItems = $el.find('[data-animator]');
    
    $animatorItems.each(el => {
        const $el = $(el);
        const def = $el.data('animator');
        def.element = el;
        initElement(def);
        register(def);
    });
};

const initElement = def => {
    const $el = $(def.element);
    
    if (def.type === 'fadeIn' || def.type === 'fadeInFast') {
        def.offsetLength = 0.1
        gsap.set($el.nodes, { opacity: 0 });
    } else if (def.type === 'fadeInUp') {
        def.offsetLength = 0.2
        gsap.set($el.nodes, { opacity: 0, y: 30 });
    }
};

const register = def => {
    updateDefinition(def);

    items.push(def);
    sortItems();

    clearTimeout(recheckTimeout);
    recheckTimeout = setTimeout(() => {
        onScroll();
    }, 50);
};

const updateDefinition = def => {
    if (def.element !== undefined) {
        const $el = $(def.element);
        def.offsetY = $el.offset().top;
        def.height = $el.height();
        def.done = false;
    }
};

const sortItems = () => {
    items.sort((a, b) => (a.offsetY > b.offsetY) ? 1 : ((b.offsetY > a.offsetY) ? -1 : 0));
};

const animateItem = (item, delay) => {
    if (item.type === 'custom') {
        item.callback(delay);
    } else if (item.type === 'fadeIn') {
        gsap.to(item.element, { duration: shouldAnimate() ? 0.8 : 0, delay: shouldAnimate() ? delay : 0, opacity: 1, ease: 'sine.out' });
    } else if (item.type === 'fadeInFast') {
        gsap.to(item.element, { duration: shouldAnimate() ? 0.3 : 0, delay: shouldAnimate() ? delay : 0, opacity: 1, ease: 'sine.out' });
    } else if (item.type === 'fadeInUp') {
        gsap.to(item.element, { duration: shouldAnimate() ? 1.2 : 0, delay: shouldAnimate() ? delay : 0, opacity: 1, y: 0, ease: 'quart.out' });
    }
};

const completeItem = (item) => {
    if (item.type === 'custom') {
        item.callback(0, true);
    } else if (item.type === 'fadeIn' || item.type === 'fadeInFast') {
        gsap.set(item.element, { opacity: 1 });
    } else if (item.type === 'fadeInUp') {
        gsap.set(item.element, { opacity: 1, y: 0 });
    }
};

const onScroll = () => {
    const top = Viewport.scrollTop;
    const viewportHeight = Viewport.height;

    let itemsToAnimate = [];
    let itemsToComplete = [];

    items.forEach(item => {
        if (!item.done) {
            if (item.offsetY + item.height < top) {
                itemsToComplete.push(item);
                item.done = true;
            } else if (item.offsetY < top + (viewportHeight * 0.9)) {
                itemsToAnimate.push(item);
                item.done = true;
            }
        }
    });

    if (itemsToComplete.length > 0) {
        itemsToComplete.forEach(item => {
            completeItem(item);
        });
    }

    let accumulatedDelay = Math.max(0, lastEventDelay - (((new Date()).getTime() - lastEventTime)/1000));
    
    if (itemsToAnimate.length > 0) {
        itemsToAnimate.forEach(item => {
            animateItem(item, accumulatedDelay);
            accumulatedDelay += Number.isNaN(item.offsetLength) ? 0 : item.offsetLength;
        });
    }
    
    lastEventDelay = accumulatedDelay;
    lastEventTime = (new Date()).getTime();
};

const onResize = () => {
    items.forEach(item => {
        if (!item.done) {
            updateDefinition(item);
        }
    });

    clearTimeout(recheckTimeout);
    recheckTimeout = setTimeout(() => {
        onScroll();
    }, 50);
};

export default {
    init,
    register,
    addElements
};
