import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

import * as Events from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $menuToggle = $el.find('[data-menu-toggle]');
    const $menu = $el.find('#menu');
    const $menuContents = $menu.find('[data-menu-contents]');
    
    let menuIsOpen = false;
    let focusedElement = null;
    let menuToggleTimeline = null;

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 80;
    let currentScrollTop = Viewport.scrollTop;
    
    const init = () => {
        const navId = $menuToggle.attr('href');

        $menuToggle.attr({
            tabIndex: '0',
            role: 'button',
            'aria-expanded': 'false'
        }).on('click', e => {
            e.preventDefault();
            toggleMenu(false);
        }).on('keydown', e => {
            const key = e.key || e.keyCode || e.which || null;
            if (['Enter', 13].indexOf(key) > -1) {
                e.preventDefault();
                toggleMenu(false);
            }
        }).get(0).removeAttribute('href');

        $menu.on('click', e => {
            if (menuIsOpen && e.target === e.triggerTarget) {
                closeMenu();
            }
        });
        
        $menu.find('a').on('click', e => {
            if (menuIsOpen) {
                closeMenu(true);
            }
        });
        
        createMenuToggleTimeline();
        
        document.addEventListener('keyup', onKeyUp);

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                window.location.hash = '';
                openMenu(true);
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });
        
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        Dispatch.on(Events.TRANSITION_OUT, transitionOut);

        $el.on('focusin', 'a,button', onInnerFocus);
        onResize();
    };

    const destroy = () => {
        $menuToggle.off('click').off('keydown');
        $menu.off('click');
        $menu.find('a').off('click');
        document.removeEventListener('keyup', onKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click focusin');
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (menuIsOpen && key === 27) {
            closeMenu();
        }
    };
    
    const transitionOut = () => {
        show();
    };

    /**
     * Scroll behaviour
     */
    const hide = (force) => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');
            
            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600)
        }
        
        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const onBreakpoint = () => {

    };    

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }
        
        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        show();
    };

    const getThreshold = () => {
        return 110;
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    /*
     * Open/close
     */
    const toggleMenu = forced => {
        if (menuIsOpen) {
            closeMenu(forced);
        } else {
            openMenu(forced);
        }
    };

    const openMenu = forced => {
        $menu.css({ display: 'block' });

        gsap.set($menu.get(0), { height: 0, opacity: 1 });
        gsap.set($menuContents.get(0), { opacity: 0 });
        gsap.to($menu.get(0), { duration: forced ? 0 : 0.4, height: '100%', ease: 'quint.out' });
        gsap.to($menuContents.get(0), { duration: forced ? 0 : 0.6, delay: forced ? 0 : 0.3, opacity: 1, ease: 'sine.out' });

        $menuToggle.get(0).setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        Viewport.lockTabbing($el.get(0), $menuToggle.get(0));

        toggleMenuToggle(true, forced);
        
        lock();
        menuIsOpen = true;
    };

    const closeMenu = forced => {
        gsap.to($menuContents.get(0), { duration: forced ? 0 : 0.05, opacity: 0 });
        gsap.to($menu.get(0), {
            duration: forced ? 0 : 0.1, delay: forced ? 0 : 0.05, opacity: 0, onComplete: () => {
                $menu.css({ display: '' });
            }
        });

        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $menuToggle.get(0).setAttribute('aria-expanded', 'false');

        toggleMenuToggle(false, forced);

        unlock();
        menuIsOpen = false;
    };
    
    const createMenuToggleTimeline = () => {
        if ($menuToggle.length > 0) {
            const $innerSpans = $menuToggle.find('span > span');
            
            if ($innerSpans.length === 3) {
                const $first = $innerSpans.eq(0);
                const $second = $innerSpans.eq(1);
                const $third = $innerSpans.eq(2);

                menuToggleTimeline = gsap.timeline({ paused: true });
                menuToggleTimeline.to($first.get(0), { duration: 0.2, top: 14, ease: 'sine.in' }, 0);
                menuToggleTimeline.to($third.get(0), { duration: 0.2, top: 14, ease: 'sine.in' }, 0);
                menuToggleTimeline.to($first.get(0), { duration: 0, opacity: 0 }, 0.2);
                menuToggleTimeline.to($second.get(0), { duration: 0.3, rotate: -45, ease: 'sine.out' }, 0.2);
                menuToggleTimeline.to($third.get(0), { duration: 0.3, rotate: 45, ease: 'sine.out' }, 0.2);
                
            }
        }
        
    };

    const toggleMenuToggle = (open, forced) => {
        if (menuToggleTimeline !== null) {
            if (open) {
                menuToggleTimeline.play();
            } else {
                menuToggleTimeline.reverse();
            }
        }
    };
    
    return {
        init,
        destroy
    };

};
