import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

import * as Events from './events';
import Viewport from '../core/Viewport';

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const handleClicks = e => {
    const target = e.triggerTarget;
    const href = target.getAttribute('href');
    
    if (!isKeyCombo(e) && href && href.indexOf('#') === -1 && href.indexOf('mailto:') === -1 && href.indexOf('tel:') === -1 && href.indexOf(document.location.hostname) > -1 && target.getAttribute('target') !== '_blank' && !target.hasAttribute('download') && !target.hasAttribute('data-transition-disabled')) {
        e.preventDefault();
        Dispatch.emit(Events.TRANSITION_OUT, href);
    }
};

const transitionOut = (key, url) => {
    const items = $('[data-root-animation-element]').nodes;
    
    gsap.to(items, { duration: 0.3, opacity: 0, ease: 'sine.in', onComplete: () => {
        document.location = url;
    }});
};

const init = () => {
    Dispatch.on(Events.TRANSITION_OUT, transitionOut);
    $('body').on('click', 'a', handleClicks);
    
    window.onpageshow = e => {
        if (e.persisted) {
            $('[data-root-animation-element]').attr('style', null);
        }
    };
};

const destroy = () => {
    Dispatch.off(Events.TRANSITION_OUT, transitionOut);
    $('body').off('click', handleClicks);
};

export default {
    init,
    destroy
};
